<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
          class="logo"
          :src="appLogoImage"
          alt="logo"
        />
        
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
        <b-alert v-if="error.status" :variant="error.variant" show>
          <h4 class="alert-heading">Erreur</h4>
          <div class="alert-body">
            <span>{{ error.msg }}</span>
          </div>
        </b-alert>
          <h2 class="brand-text text-primary text-center">
            {{appName}}
          </h2>
          <hr />
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            {{ this.$coddyger.buildGreeting() }} 👋
          </b-card-title>
          
          <b-card-text class="mb-2">
            Connectez-vous à votre compte pour commencer
          </b-card-text>
            <b-alert
            v-if="checkLogoutCause === 'invalid-session'"
            variant="danger"
            show
          >
            <div class="alert-body">
              <span>Votre session a expiré. <br />Veuillez vous reconnecter</span>
            </div>
          </b-alert>

          <!-- form -->
          <validation-observer ref="loginForm">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label="Identifiant"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="grebejordan@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Mot de passe</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="disableButtonOnLoading"
                @click="login"
              >
                <span v-if="!isLoading">Se connecter</span>
                <span v-if="isLoading">
                  <b-spinner
                    small
                    type="grow"
                  />
                  Chargement...
                </span>
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BSpinner,
  BAlert,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from '@themeConfig'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
// import { initialAbility } from '@/libs/acl/config'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BAlert,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      error: {},
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      isLoading: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    disableButtonOnLoading() {
      if (this.isLoading === true) {
        return true
      }
      return false
    },
    checkLogoutCause() {
      let query = this.$route.query;
      if (query) {
        return query.ref;
      } else {
        return "";
      }
    },
  },
  created() {
    this.$coddyger.generateAccessToken().then(doc => {
      localStorage.setItem('appToken', doc)
    }).catch(err => {
      console.log('ERREUR::', err)

      this.error = {
        status: true,
        msg: err.message,
        variant: 'danger'
      }
    })
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Form Submitted',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'dashboard' })
        }
      })
    },
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.isLoading = true
          useJwt.login(
            {
              login: this.userEmail,
              password: this.password,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('appToken')}`,
              },
            },
          )
            .then(response => {
              const { status } = response
              const api = response.data
              // const { message } = response.data
              this.isLoading = true
              if (status === 200) {
                let { user, accessToken } = api.data;

                if(user.role == 'admin') {
                  localStorage.setItem("accessToken", accessToken);
                  localStorage.setItem("refreshToken", accessToken);
                  localStorage.setItem("userData", JSON.stringify(user));

                  this.$ability.update(user.ability);
                  // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                  this.$router
                    .replace(getHomeRouteForLoggedInUser(user.role))
                    .then(() => {
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: `${this.$coddyger.buildGreeting()} ${user.fullname || user.email}`,
                          icon: 'CoffeeIcon',
                          variant: 'success',
                          text: `Connexion éffectué avec succès en tant que ${user.role}!`,
                        },
                      })
                    }).catch((e) => {
                      console.log('Error::', e)
                    })
                } else {
                  this.error = {
                    status: true,
                    msg: 'Vous n\'êtes pas autorisé à vous connecter à cette plateforme',
                    variant: 'danger'
                  }
                  this.isLoading = false
                }
              } else {
                const errors = api.message

                this.swalError('Erreur', errors, 'error')
                this.isLoading = false
              }
            })
            .catch(error => {
              this.isLoading = false
              // Remove userData from localStorage
              localStorage.removeItem('userData')

              // Reset ability
              // this.$ability.update(initialAbility)
              this.$refs.loginForm.setErrors(error.message)
              console.log(error)
              this.swalError('Erreur', error.message, 'error')
              // this.isLoading = false
            })
        }
      })
    },
    toastError(title, message, type) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon: 'WarningIcon',
          variant: type,
          text: message,
        },
      })
    },
    swalError(title, message, type) {
      this.$swal({
        title,
        text: message,
        icon: type,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  },
  setup() {
    // App Name
    const { appName, appLogoImage, appDesc } = $themeConfig.app

    return {
      appName,
      appLogoImage,
      appDesc,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
<style scoped>
  .logo {
    max-height: 35px;
  }
</style>
